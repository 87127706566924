var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"bank-transfers-table","fluid":"","tag":"section"}},[_c('base-material-card',{attrs:{"icon":"mdi-bank-transfer","title":_vm.$t('bank transfers.page title')}},[(_vm.linkTransfers)?_c('link-transfer-form',{ref:"editForm",on:{"itemSaved":_vm.onSaved}}):_vm._e(),(_vm.linkTransfers)?_c('topup-form',{ref:"topupForm",on:{"itemSaved":_vm.onTopupSaved}}):_vm._e(),_c('delete-dialog',{ref:"deleteConfirm",attrs:{"url":_vm.apiQuery,"title":_vm.$t('bank transfers.ignore')},on:{"itemDeleted":_vm.onIgnored},scopedSlots:_vm._u([{key:"default",fn:function(item){return [_c('strong',[_vm._v(_vm._s(item.purpose))]),_c('br'),_vm._v(" "+_vm._s(_vm.$t("bank transfers.confirm ignore"))+" ")]}},{key:"error",fn:function(error){return [(error.message)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(_vm._s(error.message))]):_vm._e()]}},{key:"confirmed",fn:function(item){return [(item.id)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(" "+_vm._s(_vm.$t("bank transfers.status.ignored"))+" ")]):_vm._e()]}}])}),_c('v-data-table',{staticClass:"pt-8",attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.total,"loading":_vm.loading,"multi-sort":"","sort-by":"id","sort-desc":"","footer-props":{
        'items-per-page-options': [10, 20, 50, 100, 200],
      }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{staticClass:"search-field",attrs:{"append-icon":"search","label":_vm.$t('search'),"single-line":"","hide-details":""},on:{"change":_vm.changeOutput},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('transactions.status'),"hide-details":"","items":_vm.statuses},on:{"change":_vm.changeOutput},model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}}),_c('v-select',{staticClass:"search-field",attrs:{"item-value":"id","label":_vm.$t('transactions.direction'),"hide-details":"","items":_vm.directions},on:{"change":_vm.changeOutput},model:{value:(_vm.direction),callback:function ($$v) {_vm.direction=$$v},expression:"direction"}})],1)]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [(item.isNew)?_c('v-chip',{attrs:{"color":"green"}},[_vm._v(_vm._s(item.id))]):_c('span',[_vm._v(_vm._s(item.id))])]}},{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.date).format("ll"))+" ")]}},{key:"item.transaction_id",fn:function(ref){
      var item = ref.item;
return [(
            _vm.linkTransfers &&
            item.status == 'imported' &&
            item.transaction_id === null
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('bank transfers.link transfer')},on:{"click":function($event){return _vm.onEdit(item)}}},[_vm._v("mdi-link-variant-plus")]):_vm._e(),(item.status == 'imported' && item.transaction_id === null)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('bank transfers.ignore')},on:{"click":function($event){return _vm.onDelete(item)}}},[_vm._v("mdi-link-variant-remove")]):_vm._e(),(
            _vm.linkTransfers &&
            item.status == 'imported' &&
            item.transaction_id === null
          )?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","title":_vm.$t('bank transfers.topup')},on:{"click":function($event){return _vm.onTopup(item)}}},[_vm._v("mdi-wallet-plus")]):_c('span',[_vm._v(_vm._s(item.transaction_id))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }