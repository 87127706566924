<template>
  <div id="main-content-bank-transactions">
    <bank-transfers-table></bank-transfers-table>
  </div>
</template>

<script>
import BankTransfersTable from './tables/BankTransfersTable'
export default {
  name: 'BankTransfers',
  components: {
    BankTransfersTable
  }
}
</script>