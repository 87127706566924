<template>
  <v-container id="bank-transfers-table" fluid tag="section">
    <base-material-card
      icon="mdi-bank-transfer"
      :title="$t('bank transfers.page title')"
    >
      <link-transfer-form
        v-if="linkTransfers"
        ref="editForm"
        @itemSaved="onSaved"
      ></link-transfer-form>
      <topup-form
        v-if="linkTransfers"
        ref="topupForm"
        @itemSaved="onTopupSaved"
      ></topup-form>
      <delete-dialog
        ref="deleteConfirm"
        :url="apiQuery"
        :title="$t('bank transfers.ignore')"
        @itemDeleted="onIgnored"
      >
        <template v-slot:default="item">
          <strong>{{ item.purpose }}</strong
          ><br />
          {{ $t("bank transfers.confirm ignore") }}
        </template>

        <template v-slot:error="error">
          <v-alert v-if="error.message" tile class="mb-0" type="error">{{
            error.message
          }}</v-alert>
        </template>

        <template v-slot:confirmed="item">
          <v-alert v-if="item.id" tile type="success" class="mb-0">
            {{ $t("bank transfers.status.ignored") }}
          </v-alert>
        </template>
      </delete-dialog>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"
        multi-sort
        sort-by="id"
        sort-desc
        :footer-props="{
          'items-per-page-options': [10, 20, 50, 100, 200],
        }"
        class="pt-8"
      >
        <template v-slot:top>
          <v-toolbar flat color="white">
            <v-text-field
              @change="changeOutput"
              class="search-field"
              v-model="search"
              append-icon="search"
              :label="$t('search')"
              single-line
              hide-details
            ></v-text-field>
            <v-select
              v-model="status"
              class="search-field"
              item-value="id"
              :label="$t('transactions.status')"
              hide-details
              :items="statuses"
              @change="changeOutput"
            ></v-select>
            <v-select
              v-model="direction"
              class="search-field"
              item-value="id"
              :label="$t('transactions.direction')"
              hide-details
              :items="directions"
              @change="changeOutput"
            ></v-select>
          </v-toolbar>
        </template>

        <template v-slot:item.id="{ item }">
          <v-chip v-if="item.isNew" color="green">{{ item.id }}</v-chip>
          <span v-else>{{ item.id }}</span>
        </template>
        <template v-slot:item.date="{ item }">
          {{ moment(item.date).format("ll") }}
        </template>

        <template v-slot:item.transaction_id="{ item }">
          <v-icon
            v-if="
              linkTransfers &&
              item.status == 'imported' &&
              item.transaction_id === null
            "
            small
            class="mr-2"
            @click="onEdit(item)"
            :title="$t('bank transfers.link transfer')"
            >mdi-link-variant-plus</v-icon
          >
          <v-icon
            v-if="item.status == 'imported' && item.transaction_id === null"
            small
            class="mr-2"
            :title="$t('bank transfers.ignore')"
            @click="onDelete(item)"
            >mdi-link-variant-remove</v-icon
          >
          <v-icon
            v-if="
              linkTransfers &&
              item.status == 'imported' &&
              item.transaction_id === null
            "
            small
            class="mr-2"
            @click="onTopup(item)"
            :title="$t('bank transfers.topup')"
            >mdi-wallet-plus</v-icon
          >
          <span v-else>{{ item.transaction_id }}</span>
        </template>
      </v-data-table>
    </base-material-card>
  </v-container>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudTable";
import linkTransferForm from "../forms/LinkTransferForm";
import topupForm from "../forms/TopupForm";
import deleteDialog from "@/components/crud/DeleteDialog";

export default {
  components: {
    linkTransferForm,
    topupForm,
    deleteDialog,
  },
  mixins: [crudMixin],
  created() {},
  data() {
    return {
      linkTransfers: true,
      apiQuery: "/bank/transfers/",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: this.$t("bank transfers.table.date"), value: "date" },
        { text: this.$t("bank transfers.table.reference"), value: "reference" },
        {
          text: this.$t("bank transfers.table.trans id"),
          value: "bank_trans_id",
        },
        { text: this.$t("bank transfers.table.from"), value: "from" },
        { text: this.$t("bank transfers.table.purpose"), value: "purpose" },
        {
          text: this.$t("bank transfers.table.amount"),
          value: "amount",
          align: "right",
        },
        { text: this.$t("bank transfers.table.currency"), value: "currency" },

        {
          text: this.$t("bank transfers.table.transaction"),
          value: "transaction_id",
        },
        { text: this.$t("bank transfers.table.info"), value: "info" },
      ],
      search: "",
      status: null,
      statuses: [
        { text: this.$t("bank transfers.status.all"), id: null },
        { text: this.$t("bank transfers.status.imported"), id: "imported" },
        { text: this.$t("bank transfers.status.ignored"), id: "ignored" },
        {
          text: this.$t("bank transfers.status.processed"),
          id: "processed",
        },
      ],
      direction: "",
      directions: [
        {
          id: "",
          text: this.$t("transactions.is_payout.ALL"),
        },
        {
          id: "0",
          text: this.$t("transactions.is_payout.0"),
        },
        {
          id: "1",
          text: this.$t("transactions.is_payout.1"),
        },
      ],
    };
  },
  methods: {
    onEdit(item) {
      this.$refs.editForm.editItem(item, this.items.indexOf(item));
    },
    onSaved(res, index) {
      this.items[index].transaction_id = res.transaction;
      this.items[index].status = "processed";
      window.setTimeout(() => {
        this.$refs.editForm.closeForm();
      }, 2500);
    },
    onTopup(item) {
      this.$refs.topupForm.editItem(item, this.items.indexOf(item));
    },
    onTopupSaved(res, index) {
      this.items[index].transaction_id = res.transaction;
      this.items[index].status = "processed";
      window.setTimeout(() => {
        this.$refs.topupForm.closeForm();
      }, 2500);
    },
    onIgnored(item, index) {
      this.items[index].status = "ignored";
    },
    changeOutput() {
      this.$set(this.options, "search", this.search);
      this.$set(this.options, "status", this.status);
      this.$set(this.options, "is_payout", this.direction);
      this.options.page = 1;
    },
    moment,
  },
};
</script>

<style scoped>
.search-field {
  margin-right: 10px;
}
</style>
