var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"90vw","width":"500px"},on:{"click:outside":_vm.closeForm},model:{value:(_vm.showFormDialog),callback:function ($$v) {_vm.showFormDialog=$$v},expression:"showFormDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.saveItem($event)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-text',[_c('v-container',[(_vm.editedItem)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_vm._v(" "+_vm._s(_vm.editedItem.purpose)),_c('br'),_c('h5',[_vm._v(_vm._s(_vm.moment(_vm.editedItem.date).format("ll")))]),_c('h4',[_vm._v(_vm._s(_vm.editedItem.amount + " " + _vm.editedItem.currency))]),_c('v-autocomplete',{attrs:{"search-input":_vm.search,"items":_vm.addressItems,"item-text":"transaction_id","item-value":"transaction_id","filter":_vm.customFilter,"clearable":"","name":"transaction","label":_vm.$t('transactions.tid'),"prepend-icon":"mdi mdi-bank-transfer","rules":_vm.rules.transaction},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(
                        item.gateway +
                        ': ' +
                        item.transaction_id +
                        ' - ' +
                        item.user +
                        '(' +
                        item.client +
                        ')'
                      )}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.purpose)}}),(item.type == 'batch')?_c('i',[_c('b',[_vm._v("("+_vm._s(item.count)+")")]),_vm._v(" "+_vm._s(item.transactions))]):_vm._e(),_vm._v(" "+_vm._s(item.status)+" "),_c('b',[_vm._v(" "+_vm._s(item.amount + " " + item.currency)+" ")]),_vm._v(" "+_vm._s(_vm.moment(item.created_at).format("lll"))+" ")],1)]}}],null,false,3708503073),model:{value:(_vm.form.transaction),callback:function ($$v) {_vm.$set(_vm.form, "transaction", $$v)},expression:"form.transaction"}})],1)],1):_vm._e()],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"gray","disabled":_vm.sending || _vm.savedItem != null},on:{"click":_vm.closeForm}},[_vm._v(_vm._s(_vm.$t("Cancel")))]),_c('v-btn',{attrs:{"type":"submit","disabled":_vm.sending || _vm.savedItem != null,"color":"primary"}},[_vm._v(_vm._s(_vm.$t("Save")))])],1)],1),(_vm.sending)?_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","height":"6"}}):_vm._e(),(_vm.errorMessage)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"error"}},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e(),(_vm.savedItem)?_c('v-alert',{staticClass:"mb-0",attrs:{"tile":"","type":"success"}},[_vm._v(_vm._s(_vm.$t("bank transfers.saved", _vm.savedItem)))]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }