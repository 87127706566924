<template>
  <v-dialog
    v-model="showFormDialog"
    max-width="90vw"
    width="500px"
    @click:outside="closeForm"
  >
    <v-card>
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-form ref="form" v-model="valid" @submit.prevent="saveItem">
        <v-card-text>
          <v-container>
            <v-row v-if="editedItem">
              <v-col cols="12" md="12">
                {{ editedItem.purpose }}<br />
                <h5>{{ moment(editedItem.date).format("ll") }}</h5>
                <h4>{{ editedItem.amount + " " + editedItem.currency }}</h4>
                <v-autocomplete
                  v-model="form.transaction"
                  :search-input.sync="search"
                  :items="addressItems"
                  item-text="transaction_id"
                  item-value="transaction_id"
                  :filter="customFilter"
                  clearable
                  name="transaction"
                  :label="$t('transactions.tid')"
                  prepend-icon="mdi mdi-bank-transfer"
                  :rules="rules.transaction"
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="
                          item.gateway +
                          ': ' +
                          item.transaction_id +
                          ' - ' +
                          item.user +
                          '(' +
                          item.client +
                          ')'
                        "
                      ></v-list-item-title>
                      <v-list-item-subtitle
                        v-text="item.purpose"
                      ></v-list-item-subtitle>
                      <i v-if="item.type == 'batch'">
                        <b>({{ item.count }})</b>
                        {{ item.transactions }}</i
                      >
                      {{ item.status }}
                      <b> {{ item.amount + " " + item.currency }} </b>
                      {{ moment(item.created_at).format("lll") }}
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="gray"
            :disabled="sending || savedItem != null"
            @click="closeForm"
            >{{ $t("Cancel") }}</v-btn
          >
          <v-btn
            type="submit"
            :disabled="sending || savedItem != null"
            color="primary"
            >{{ $t("Save") }}</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-progress-linear
        v-if="sending"
        color="deep-purple accent-4"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>
      <v-alert v-if="errorMessage" tile class="mb-0" type="error">
        {{ errorMessage }}
      </v-alert>
      <v-alert v-if="savedItem" tile type="success" class="mb-0">{{
        $t("bank transfers.saved", savedItem)
      }}</v-alert>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import crudMixin from "mixins/crudForm";

export default {
  mixins: [crudMixin],
  props: {},
  data() {
    return {
      apiQuery: "/bank/transfers/",
      currency: null,
      search: null,
      lastSearch: null,
      rules: {
        transaction: [(v) => !!v || "Required."],
      },
      addressItems: [],
    };
  },
  watch: {
    search(val) {
      val && val !== this.form.transaction && this.querySelections(val);
    },
  },
  computed: {
    formTitle() {
      return this.$t("bank transfers.link transfer");
    },
  },
  methods: {
    querySelections(v) {
      if (v.length < 3) {
        return;
      }
      if (v.indexOf(" ") > -1) {
        v = v.substring(0, v.indexOf(" "));
      }
      if (this.lastSearch == v) {
        return;
      }
      this.lastSearch = v;
      this.loading = true;
      this.get("/transactions/search", {
        search: v,
      })
        .then((res) => {
          this.addressItems = res;
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => (this.loading = false));
    },
    resetValidation() {
      this.$refs.form.resetValidation();
      this.search = null;
    },
    editItem(item, index) {
      if (this.$refs.form) {
        this.resetValidation();
      }
      this.editedItem = item;
      this.editedIndex = index;
      this.form = Object.assign({}, this.defaultItem);
      this.form.id = item.id;
      this.showFormDialog = true;
    },
    customFilter(item, queryText) {
      return item.search_id.indexOf(queryText) > -1;
    },
    moment,
  },
};
</script>
